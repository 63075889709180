<template>
  <div class="app-wrap">
    <div class="compass-loader" :class="{ 'loaded': !showLoading }">
      <img src="../assets/logo_compass.png" class="compass" alt />
      <p>Building your leadership profile</p>
    </div>
    <div class="content-wrap content-wrap--report">
      <div class="row">
        <div class="col-xs-12 text--center">
          <h1 class="leadership-title">
            Your leadership profile
            <span class="teal" v-if="vueRoot.userName">{{vueRoot.userName}}</span>
          </h1>
          <button
            v-if="vueRoot.averageRatings.length > 0 !== null && vueRoot.averageOrder.length > 0"
            class="btn btn--primary btn--action"
            @click="savePdf()"
          >
            <font-awesome-icon icon="download" />Download PDF
          </button>
          <button
            v-if="vueRoot.averageRatings.length > 0 !== null && vueRoot.averageOrder.length > 0"
            class="btn btn--primary btn--action"
            @click="toggleEmail = !toggleEmail"
          >
            <font-awesome-icon icon="paper-plane" />Send PDF
          </button>
          <div v-if="emailLoading" class="text--center">
            <p class="teal">Sending...</p>
          </div>
          <div v-if="emailSent" class="text--center">
            <p class="teal">Email sent successfully.</p>
          </div>
          <div v-if="toggleEmail" class="email-wrap">
            <div class="wrap">
              <input
                class="email-field"
                v-model="userEmail"
                type="email"
                placeholder="Email address"
              />
              <button class="btn btn--primary btn--action btn--email" @click="sendPdf()">Send</button>
            </div>
          </div>
        </div>
        <div class="col-xs-12 hidden-sm text--center">
          <div class="area-title-container">
            <h2 class="area-title">Leadership profile</h2>
          </div>
          <div class="comment-wrap text--center">
            <p>In the diagram below you will see your result from the sorting exercise. The closer your distribution is to the outer edge of the graph, the more important this competency is to you!</p>
            <p>You may notice some differences in how you have prioritised the 11 competencies compared to your peers. You can see this through the difference between your distribution, and the Average Distribution</p>
          </div>
          <apexchart class="apex-container" type="radar" :options="chartOptions" :series="series"></apexchart>
          <apexchart class="apex-container" width="1000" height="500" style="display: none" ref="spiderchart" type="radar" :options="chartOptions" :series="series"></apexchart>
        </div>
        <div class="col-sm-6">
          <div class="area-title-container">
            <h2 class="area-title">Your competencies</h2>
          </div>
          <div class="comment-wrap text--center">
            <p>Below you will find a summary of your top rated competencies, as compared to your peers.</p>
            <p>Consider the comparison to your peers.</p>
          </div>
          <div
            v-for="(element, index) in limitedCompetencies"
            :key="element.order"
            class="report-competency"
          >
            <div class="report-competency__top">
              {{index+1}}. {{ element.data.competencyTitle }}
              <span
                v-if="element.average"
                class="report-competency__average-number"
                content="Average order"
                v-tippy="{ placement : 'left', arrow: true }"
              >{{element.average}}</span>
            </div>
            <div class="report-competency__bottom">
              <p>{{ element.data.competencyDescription }}</p>
            </div>
          </div>
          <button @click="limit = 20" v-if="limit == 3" class="view-more">
            <font-awesome-icon icon="chevron-down" />Show more
          </button>
          <button @click="limit = 3" v-if="limit == 20" class="view-more">
            <font-awesome-icon icon="chevron-up" />Show less
          </button>
          <hr />
          <div class="area-title-container">
            <h2 class="area-title">Questions &amp; Answers</h2>
          </div>
          <div class="report-question">
            <div
              class="report-question__top"
            >As you were completing the sorting exercise, what were you thinking about in your current situation?</div>
            <div class="report-question__bottom">
              <p>{{vueRoot.userAnswers[0]}}</p>
            </div>
          </div>
          <div class="report-question">
            <div class="report-question__top">
              You seem to emphasise leading with a focus on
              <span
                class="teal"
              >{{vueRoot.userCompetencies[0].data.competencyKeyword}}</span>,
              <span class="teal">{{vueRoot.userCompetencies[1].data.competencyKeyword}}</span>, and
              <span class="teal">{{vueRoot.userCompetencies[2].data.competencyKeyword}}</span>. How do you feel these help you in this situation?
            </div>
            <div class="report-question__bottom">
              <p>{{vueRoot.userAnswers[1]}}</p>
            </div>
          </div>
          <div class="report-question">
            <div
              class="report-question__top"
            >What do you anticipate will change for you (e.g. role, context) in the next 12 months?</div>
            <div class="report-question__bottom">
              <p>{{vueRoot.userAnswers[2]}}</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="area-title-container">
            <h2 class="area-title">Your focus areas</h2>
          </div>
          <div class="comment-wrap">
            <p>Below you will find a summary of your focus areas, relative to your peers.</p>
            <p>Look out for areas of least and most difference.</p>
            <p>
              <strong>When comparing yourself with your peers, consider the following:</strong>
            </p>
            <p>
              &middot; What do you want to KEEP doing?
              <br />(focus areas that are driving your success)
            </p>
            <p>
              &middot; What do you want to START doing?
              <br />(new focus areas, gradually introduced)
            </p>
            <p>&middot; Where could you reprioritise your efforts?</p>
          </div>
          <ReportSlider
            v-for="(element, index) in combinedRatings"
            :key="index"
            v-bind:rating="element[0]"
            v-bind:average="element[1]"
            v-bind:question="element[2]"
          ></ReportSlider>
        </div>
      </div>

      <div class="cta-wrap">
        <div class="row">
          <div class="col-xs-12 text--center">
            <div class="comment-wrap">
              <p>Congratulations! You’re on track to steering your development in a clearer direction.</p>
              <p>
                <strong>What's next?</strong>
              </p>
              <p>Considering your responses, think about how you might like to apply your new insights. Here are some ideas to get you started:</p>
              <ul>
                <li>24-7-1</li>
                <ul>
                  <li>What is one change you can make in the next 24 hours?</li>
                  <li>What is one progressive step you can make in the next 7 days?</li>
                  <li>What do you want to achieve in one month?</li>
                </ul>
                <li>
                  You can use your report to support conversations with your supervisor about your development,
                  including new training opportunities, on-the-job experiences and self-paced learning.
                </li>
                <li>
                  You can read the
                  <a
                    href="https://www.forgov.qld.gov.au/career-conversations-guide"
                    target="_blank"
                  >Career conversations guide</a> to help you reflect on your job, what you would like
                  to improve, and where you may like your career to go.
                </li>
                <li>
                  You can explore the
                  <a
                    href="https://www.forgov.qld.gov.au/leadership-and-learning-hub"
                  >Leadership and learning hub</a> and discover a range of resources to keep you
                  moving forward.
                </li>
                <li>
                  You can
                  <a
                    href="https://www.vision6.com.au/forms/s/6324daa/29391/445551/167518.html"
                    target="_blank"
                  >subscribe for updates</a> from the Public Service Commission about leadership events,
                  scholarships and news
                </li>
              </ul>
              <p>
                Click
                <a href="https://www.surveymonkey.com/r/R8F2LPM" target="_blank">here</a> to provide your feedback on this experience.
              </p>
              <p>
                <router-link to="/" @click.native="vueRoot.resetValues()">Re-take assessment</router-link>
              </p>
            </div>
            <a
              href="https://www.forgov.qld.gov.au/leadership-and-learning-hub"
              class="btn btn--primary"
            >Visit the Leadership and learning hub</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../main.js";
import ReportSlider from "../components/ReportSlider.vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
// jsPDF
import * as jsPDF from "jspdf";

export default {
  name: "LevelSelection",
  components: { ReportSlider },
  mounted: function() {
    this.checkInit();
  },
  computed: {
    vueRoot() {
      return this.$root;
    },
    // Limit to 3 / show all
    limitedCompetencies() {
      return this.limit
        ? this.combinedOrders.slice(0, this.limit)
        : this.combinedOrders;
    },
    // Combine card order with average
    combinedOrders() {
      const combinedOrder = this.vueRoot.userCompetencies;
      const averageOrder = this.vueRoot.averageOrder;

      if (averageOrder.length) {
        combinedOrder.forEach(function(original, index) {
          let averagePosition = averageOrder.indexOf(original.order - 1);
          combinedOrder[index].average = averagePosition + 1;
        });
      }

      let userChartData = [];
      let averageChartData = [];
      let categories = [];

      combinedOrder.forEach((competency, index) => {
        userChartData.push(index);
        averageChartData.push(competency.average);
        categories.push(competency.data.competencyTitle);
      });

      let mixArray = [];

      userChartData.forEach((item, index) => {
        let row = [
          categories[index],
          15 - userChartData[index],
          15 - averageChartData[index]
        ];
        mixArray.push(row);
      });
      mixArray.sort(function(a, b) {
        return a[0] > b[0] ? 1 : -1;
      });

      userChartData = [];
      averageChartData = [];
      categories = [];

      mixArray.forEach((item, index) => {
        categories.push(mixArray[index][0]);
        userChartData.push(mixArray[index][1]);
        averageChartData.push(mixArray[index][2]);
      });

      this.series[1].data = userChartData;
      this.series[0].data = averageChartData;
      this.chartOptions.xaxis.categories = categories;

      return combinedOrder;
    },
    // Combine ratings, averages and questions
    combinedRatings() {
      const combinedRatings = [];
      const transformedRatings = this.transformEmphasisRatings();
      const focusLevel = this.vueRoot.selectedLevel.value;
      const competencyDescriptions = this.vueRoot.staticData[focusLevel];
      const averageRatings = this.vueRoot.averageRatings;

      for (var key of Object.keys(transformedRatings)) {
        transformedRatings[key].forEach(function(rating, index) {
          let average = null;
          if (key in averageRatings) {
            average = averageRatings[key][index];
          }
          const description =
            competencyDescriptions[key].competencyItems[index];
          combinedRatings.push([rating, average, description]);
        });
      }
      
      return combinedRatings;
    }
  },
  methods: {
    checkInit() {
      if (this.vueRoot.selectedLevel) {
        this.getCompareData();
        this.initData();
        this.sendRatings();
      } else {
        this.$router.push({ path: "/" });
      }
    },
    // Get average data from database
    getCompareData() {
      let that = this;

      let averages = [];
      let focusLevel = this.vueRoot.selectedLevel.value;

      var getScores = firebase.functions().httpsCallable("getScores");
      
      getScores({
        focusLevel: focusLevel
      })
        .then(function(result) {
          that.vueRoot.averageRatings = result.data.averages.averageScores;
          that.vueRoot.averageOrder = result.data.orders.order;
          //console.log("Cloud function succesfully returned averages");
          setTimeout(() => {
            that.$refs.spiderchart.chart.dataURI().then(uri => {
              that.chartImg = uri;
              //console.log("chart rendered");
            }); 
          }, 1000);
        })
        .catch(function(error) {
          setTimeout(function() {
            that.showErrorDialog();
          }, 2500);
          console.log(error);
        });
    },

    showErrorDialog() {
      if (!this.maxErrors) {
        this.maxErrors = true;
        let options = {
          okText: "Retry",
          cancelText: "Cancel"
        };
        let that = this;
        this.$dialog
          .confirm(
            "Connectivity error. Please check your internet connection and retry.",
            options
          )
          .then(function(dialog) {
            that.getCompareData();
            that.maxErrors = false;
          })
          .catch(function() {
            that.maxErrors = false;
          });
      }
    },

    // Send ratings to Firebase
    sendRatings() {
      if (this.vueRoot.ratingsSubmitted) {
        return;
      }
      //console.log("Sending ratings");
      let that = this;
      let focusLevel = this.vueRoot.selectedLevel.value;
      const competencyRatings = this.transformEmphasisRatings();
      const competencyOrder = this.transformCompetencyOrder();

      var sendScores = firebase.functions().httpsCallable("sendScores");

      sendScores({
        focusLevel: focusLevel,
        competencyOrder: competencyOrder,
        competencyRatings: competencyRatings
      })
        .then(function(result) {
          that.vueRoot.ratingsSubmitted = true;
          //console.log("Ratings submitted succesfully");
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    // Transform order for upload
    transformCompetencyOrder() {
      const userCompetencies = this.vueRoot.userCompetencies;
      const competencyOrder = [];

      userCompetencies.forEach(function(competency) {
        competencyOrder.push(competency.order);
      });

      return competencyOrder;
    },

    // Transform ratings for upload
    transformEmphasisRatings() {
      let transformedRatings = {};
      for (var i = 0; i < 3; i++) {
        let key = this.vueRoot.userCompetencies[i].data.competencySlug;
        let ratings = this.vueRoot.userEmphasis[i].ratings;
        transformedRatings[key] = ratings;
      }
      return transformedRatings;
    },

    // Init
    initData() {
      var self = this;
      setTimeout(function() {
        self.showLoading = false;
      }, 5000);
    },

    createPdf() {
      let doc = new jsPDF();

      var logo = new Image();
      logo.src = require("@/assets/report_logo.png");

      var coa = new Image();
      coa.src = require("@/assets/coa.png");

      doc.addImage(logo, "PNG", 10, 10, 64, 16);
      doc.addImage(coa, "PNG", 180, 260, 18.8, 23.6);

      if(this.chartImg) {
        doc.addImage(this.chartImg, 'PNG', 80, 10, 120, 60);
      }

      let userEmphasis = this.combinedRatings;
      let userCompetencies = this.combinedOrders;
      let userName = this.vueRoot.userName;

      doc.setFontType("normal");
      doc.setFontSize(8);
      doc.setTextColor("#999");
      doc.text("Page 1/2", 10, 285);

      doc.setFontType("bold");
      doc.setFontSize(16);
      doc.setTextColor("#666");
      doc.text("Your leadership profile", 10, 40);

      doc.setFontType("normal");
      doc.setFontSize(7);
      doc.setTextColor("#666");

      splitText = doc.splitTextToSize(
        "Below you will find a summary of your top rated competencies, as compared to your peers. Consider the comparison to your peers.",
        80
      );
      doc.text(splitText, 10, 68);

      doc.setFontSize(14);
      doc.setFontType("normal");
      doc.setTextColor("#62C4AE");
      doc.text(userName, 10, 47);

      doc.setFontSize(14);
      doc.setFontType("bold");
      doc.setTextColor("#62C4AE");
      doc.text("Your competencies", 10, 82);

      doc.setFontSize(14);
      doc.setTextColor("#62C4AE");
      doc.text("Your focus areas", 78, 82);

      doc.setFontType("normal");

      doc.setFillColor("#62C4AE");
      doc.circle(142, 81, 1.5, "F");
      doc.setFontSize(8);
      doc.setTextColor("#62C4AE");
      doc.text("Your distribution", 145, 82);

      doc.setFillColor("#191847");
      doc.circle(170, 81, 1.5, "F");
      doc.setFontSize(8);
      doc.setTextColor("#191847");
      doc.text("Average distribution", 173, 82);

      doc.setFontSize(5.5);
      doc.setTextColor("#666");

      splitText = doc.splitTextToSize(
        "Below you will find a summary of your focus areas, relative to your peers. Look out for areas of least and most discrepancy. When comparing yourself with your peers, consider the following: What do you want to KEEP doing? (focus areas that are driving your success) What do you want to START doing? (new focus areas, gradually introduced). Where could you reprioritise your efforts?",
        120
      );
      doc.text(splitText, 79, 90);

      let startY = 112;
      let startX = 80;
      doc.setFontType("bold");
      userEmphasis.forEach(function(row, index) {
        doc.setFontSize(5);
        doc.setTextColor("#666");
        let splitText = doc.splitTextToSize(row[2], 55);
        doc.text(splitText, startX - 1, startY - 10);

        doc.setDrawColor("#EEEEEE");
        doc.setLineWidth(0.5);
        doc.line(startX, startY, startX + 50, startY);

        doc.setFillColor("#EEEEEE");
        doc.circle(startX, startY, 1, "F");
        doc.circle(startX + 12.5, startY, 1, "F");
        doc.circle(startX + 25, startY, 1, "F");
        doc.circle(startX + 37.5, startY, 1, "F");
        doc.circle(startX + 50, startY, 1, "F");

        // Score
        doc.setFillColor("#62C4AE");
        doc.circle(startX - 12.5 + 12.5 * row[0], startY, 1.5, "F");

        // Average
        doc.setFillColor("#191847");
        doc.circle(startX - 12.5 + 12.5 * row[1], startY, 0.75, "F");

        if (index == Math.round(userEmphasis.length / 2)) {
          startY = 112;
          startX = 145;
        } else {
          startY += 20;
        }
      });

      // Draw Dividers
      doc.setDrawColor("#f5f5f5");
      doc.setLineWidth(0.5);
      doc.line(72, 78, 72, 255);

      let splitText = "";

      startX = 10;
      startY = 91;

      userCompetencies.forEach(function(row, index) {
        doc.setFontSize(7);
        doc.setTextColor("#666");
        doc.setFontType("bold");

        splitText = doc.splitTextToSize(
          index + 1 + ". " + row.data.competencyTitle,
          60
        );
        doc.text(splitText, startX, startY);
        doc.setFontSize(7);
        doc.setFontType("normal");
        doc.setTextColor("#888");
        splitText = doc.splitTextToSize(row.data.competencyDescription, 55);
        doc.text(splitText, startX, startY + 4);

        startY = startY + 16;
      });

      // PAGE 2

      doc.addPage();

      doc.addImage(logo, "PNG", 10, 10, 64, 16);
      doc.addImage(coa, "PNG", 180, 260, 18.8, 23.6);

      doc.setFontType("normal");
      doc.setFontSize(8);
      doc.setTextColor("#999");
      doc.text("Page 2/2", 10, 285);

      doc.setFontSize(14);
      doc.setTextColor("#62C4AE");
      doc.setFontType("bold");
      doc.text("Reflections", 10, 43);

      doc.setFontSize(8);
      doc.setTextColor("#666");
      doc.setFontType("bold");
      splitText = doc.splitTextToSize(
        "As you were completing the sorting exercise, what were you thinking about in your current situation?",
        55
      );
      doc.text(splitText, 10, 50);

      doc.setFontType("normal");
      doc.setTextColor("#888");
      splitText = doc.splitTextToSize(this.vueRoot.userAnswers[0], 55);
      doc.text(splitText, 10, 63);

      let variableQuestion =
        "You seem to emphasise leading with a focus on " +
        userCompetencies[0].data.competencyKeyword +
        ", " +
        userCompetencies[1].data.competencyKeyword +
        ", and " +
        userCompetencies[2].data.competencyKeyword +
        ". How do you feel these help you in this situation?";

      doc.setFontSize(8);
      doc.setTextColor("#666");
      doc.setFontType("bold");
      splitText = doc.splitTextToSize(variableQuestion, 55);
      doc.text(splitText, 79, 50);

      doc.setFontType("normal");
      doc.setTextColor("#888");
      splitText = doc.splitTextToSize(this.vueRoot.userAnswers[1], 55);
      doc.text(splitText, 79, 73);

      doc.setFontSize(8);
      doc.setTextColor("#666");
      doc.setFontType("bold");
      splitText = doc.splitTextToSize(
        "What do you anticipate will change for you (e.g. role, context) in the next 12 months?",
        55
      );
      doc.text(splitText, 145, 50);

      doc.setFontType("normal");
      doc.setTextColor("#888");
      splitText = doc.splitTextToSize(this.vueRoot.userAnswers[2], 55);
      doc.text(splitText, 145, 63);

      doc.line(10, 195, 198, 195);

      doc.setFontSize(8);
      doc.setTextColor("#666");
      doc.setFontType("normal");
      doc.text(
        "Congratulations! You’re on track to steering your development in a clearer direction.",
        10,
        205
      );

      doc.setFontSize(10);
      doc.setTextColor("#62C4AE");
      doc.setFontType("bold");
      doc.text("What's next?", 10, 212);

      doc.setFontSize(8);
      doc.setTextColor("#666");
      doc.setFontType("bold");
      splitText = doc.splitTextToSize(
        "Considering your responses, think about how you might like to apply your new insights. Here are some ideas to get you started:",
        150
      );
      doc.text(splitText, 10, 217);

      doc.setFontSize(8);
      doc.setTextColor("#666");
      doc.setFontType("normal");
      splitText = doc.splitTextToSize(
        "24-7-1: What is one change you can make in the next 24 hours? What is one progressive step you can make in the next 7 days? What do you want to achieve in one month?",
        125
      );
      doc.text(splitText, 15, 225);

      doc.setFontSize(8);
      doc.setTextColor("#666");
      doc.setFontType("normal");
      splitText = doc.splitTextToSize(
        "You can use your report to support conversations with your supervisor about your development, including new training opportunities, on-the-job experiences and self-paced learning.",
        125
      );
      doc.text(splitText, 15, 233);

      doc.setFontSize(8);
      doc.setTextColor("#666");
      doc.setFontType("normal");

      splitText = doc.splitTextToSize(
        "You can read the Career conversations guide to help you reflect on your job, what you would like to improve, and where you may like your career to go.",
        125
      );

      doc.text(splitText, 15, 241);
      doc.setFontSize(6);
      doc.setTextColor("#62C4AE");
      doc.textWithLink("[CLICK HERE]", 80, 244, {
        url: "https://www.forgov.qld.gov.au/career-conversations-guide"
      });

      doc.setFontSize(8);
      doc.setTextColor("#666");
      doc.setFontType("normal");

      splitText = doc.splitTextToSize(
        "You can explore the Leadership and learning hub and discover a range of resources to keep you moving forward.",
        125
      );

      doc.text(splitText, 15, 249);
      doc.setFontSize(6);
      doc.setTextColor("#62C4AE");
      doc.textWithLink("[CLICK HERE]", 36, 252, {
        url: "https://www.forgov.qld.gov.au/leadership-and-learning-hub"
      });

      doc.setFontSize(8);
      doc.setTextColor("#666");
      doc.setFontType("normal");

      splitText = doc.splitTextToSize(
        "You can subscribe for updates from the Public Service Commission about leadership events, scholarships and news.",
        125
      );

      doc.text(splitText, 15, 257);
      doc.setFontSize(6);
      doc.setTextColor("#62C4AE");
      doc.textWithLink("[CLICK HERE]", 46, 260, {
        url:
          "https://www.vision6.com.au/forms/s/6324daa/29391/445551/167518.html"
      });

      doc.setFontSize(8);
      doc.setTextColor("#666");
      doc.setFontType("normal");

      splitText = doc.splitTextToSize(
        "Provide your feedback on this experience.",
        125
      );

      doc.text(splitText, 10, 265);
      doc.setFontSize(6);
      doc.setTextColor("#62C4AE");
      doc.textWithLink("[CLICK HERE]", 64, 265, {
        url: "https://www.surveymonkey.com/r/R8F2LPM"
      });

      doc.setFillColor("#62C4AE");
      doc.circle(12, 224, 0.7, "F");
      doc.circle(12, 232, 0.7, "F");
      doc.circle(12, 240, 0.7, "F");
      doc.circle(12, 248, 0.7, "F");
      doc.circle(12, 256, 0.7, "F");

      return doc; 
      
    },
    savePdf() {
      const doc = this.createPdf();
      doc.save("leadership-profile.pdf");
    },
    sendPdf() {
      const doc = this.createPdf();
      let output = doc.output("datauristring");
      let emailAddress = this.userEmail;
      let parts = output.split(",");
      let pdf = parts[1];
      let that = this;

      var pdfEmail = firebase.functions().httpsCallable("pdfEmail");
      this.emailSent = false;
      this.emailLoading = true;
      this.toggleEmail = false;
      pdfEmail({
        emailAddress: emailAddress,
        attachmentPdf: pdf
      })
        .then(function(result) {
          that.emailSent = true;
          that.emailLoading = false;
        })
        .catch(function(error) {
          console.log(error);
          that.toggleEmail = true;
        });
    }
  },
  data: function() {
    return {
      limit: 3,
      emphasisDescriptions: [],
      emphasisRatings: [],
      userRatings: [],
      userEmail: "",
      maxErrors: false,
      showLoading: true,
      toggleEmail: false,
      emailLoading: false,
      emailSent: false,
      chartImg: null,
      chartOptions: {
        chart: {
          id: "leadership-triangle",
          toolbar: {
            show: false
          }
        },
        grid: {
          padding: {
            left: 0,
            right: 0
          }
        },
        labels: [],
        yaxis: {
          show: false,
          tickAmout: 3
        },
        tooltip: {
          enabled: false
        },
        markers: {
          discrete: [],
          size: 0
        },
        xaxis: {
          type: "category",
          categories: [
            "Leads Strategically",
            "Stimulates ideas and innovation",
            "Leads change in complex environments",
            "Makes insightful decisions",
            "Develops and mobilises talent",
            "Builds enduring relationships",
            "Inspires others",
            "Drives accountability and outcomes",
            "Fosters healthy and inclusive workplaces",
            "Pursues continuous growth",
            "Demonstrates sound governance"
          ]
        },
        legend: {
          show: true
        },
        stroke: {
          width: 0
        },
        fill: {
          opacity: 0.75
        },
        colors: ["#191847", "#62C4AE"],
        plotOptions: {
          radar: {
            polygons: {}
          }
        }
      },
      series: [
        {
          name: "Average distribution",
          data: []
        },
        {
          name: "Your distribution",
          data: []
        }
      ]
    };
  }
};
</script>
