<template>
  <EmphasisSlider :section="0" link="/emphasis/2"></EmphasisSlider>
</template>
<script>
import EmphasisSlider from "../components/EmphasisSlider.vue";
export default {
  name: "Emphasis1",
  components: { EmphasisSlider },
  computed: {
    vueRoot() {
      return this.$root;
    }
  },
  mounted: function() {
    this.vueRoot.checkProgression(2);
  },
  data: function() {
    return {};
  }
};
</script>