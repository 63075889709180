<template>
  <div id="app" :class="this.$route.name">
    <AppHeader/>
    <transition name="page" mode="out-in">
      <router-view></router-view>
    </transition>
    <modal></modal>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";

export default {
  name: "app",
  components: {
    AppHeader
  },
  data: function() {
    return {};
  }
};
</script>
<style lang="scss">
@import "./src/css/style.scss";
</style>