<template>
  <div class="app-wrap app-wrap--grey">
    <div class="content-wrap">
      <CardSort></CardSort>
    </div>
    <div class="cta-wrap">
      <div class="row">
        <div class="col-xs-12 text--center">
          <router-link
            to="/questions"
            class="btn btn--primary"
          >Continue</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardSort from "../components/CardSort.vue";
export default {
  name: "LevelSelection",
  components: {
    CardSort
  },
  computed: {
    vueRoot() {
      return this.$root;
    }
  },
  mounted: function() {
    this.vueRoot.checkProgression(0);
  },
  data: function() {
    return {
    };
  }
};
</script>
