<template>
  <div class="modal-wrap">
    <div
      class="modal"
      :class="{ 'modal--hidden': !vueRoot.modalActive }"
      v-if="currentPath == '/competencies'"
    >
      <div class="modal__container">
        <img src="../assets/guide_1.svg" alt />
        <div class="modal__container__content">
          <h1 class="section-title">Part 2: Competencies</h1>
          <p>
            <a href="https://www.forgov.qld.gov.au/leadership-competencies-queensland" target="_blank">Leadership competencies for Queensland</a> describes what highly effective, everyday leadership looks
            like in the sector.
          </p>
          <p>
            <strong>Activity:</strong> Reflect on your current role and context and sort the 11 leadership competencies in order
            of importance. Place your most important competencies at the top.
          </p>
          <p>Note, you can keep re-arranging the competencies until you are happy with the order.</p>
          <button @click="vueRoot.hideModal" class="btn btn--primary">Continue</button>
        </div>
      </div>
    </div>
    <div
      class="modal"
      :class="{ 'modal--hidden': !vueRoot.modalActive }"
      v-if="currentPath == '/questions'"
    >
      <div class="modal__container">
        <img src="../assets/guide_2.svg" alt />
        <div class="modal__container__content">
          <h1 class="section-title">Part 3: Reflection</h1>
          <p>Let’s keep your reflection going…</p>
          <p><strong>Activity:</strong> Answer the following questions about the leadership competencies you’ve
prioritised in your role, think about why these are important now and how these might
change in the next 12 months.</p>
          <button @click="vueRoot.hideModal" class="btn btn--primary">Continue</button>
        </div>
      </div>
    </div>
    <div
      class="modal"
      :class="{ 'modal--hidden': !vueRoot.modalActive }"
      v-if="currentPath == '/emphasis/1' || currentPath == '/emphasis/2' || currentPath == '/emphasis/3'"
    >
      <div class="modal__container">
        <img src="../assets/guide_3.svg" alt />
        <div class="modal__container__content">
          <h1 class="section-title">Part 4: Emphasis</h1>
          <p>Understand the behaviours that underpin your top three competencies.</p>
          <p>
            To strengthen our impact as leaders, we must understand the behaviours that make us successful. Here, you will find the behaviours that underpin the competencies you identified as most
important to you right now.
          </p>
          <p>
            <strong>Activity:</strong> Think about the behaviour/s that are of greatest importance in your current role and
context. Distribute points to each series of behaviours by moving the slider from ‘least important’ to
‘most important’.
          </p>
          <p>
            Note – it’s up to you how you would like to distribute your points. For example, you might like to
allocate all available points to one behaviour or distribute your points across multiple behaviours.
          </p>
          <button @click="vueRoot.hideModal" class="btn btn--primary">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Modal",
  computed: {
    vueRoot() {
      return this.$root;
    },
    currentPath() {
      return this.$route.path;
    }
  }
};
</script>