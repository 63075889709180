<template>
  <div class="app-wrap">
    <div class="content-wrap content-wrap--emphasis">
      <h2 class="page-title">
        Distribute points by importance.
        <span class="points-left">{{pool}}</span> points left.
      </h2>
      <div v-for="(item, index) in questionData" class="emphasis-slider" :key="index">
        <h4 class="question">{{item}}</h4>
        <vue-slider
          v-model="vueRoot.userEmphasis[section].ratings[index]"
          :key="index"
          tooltip="none"
          :adsorb="true"
          :min="1"
          :max="5"
          :included="true"
          :marks="marks"
          @change="adjustToPointBounds(section,index)"
        ></vue-slider>
      </div>
      <div class="cta-wrap">
        <div class="row">
          <div class="col-xs-12 text--center">
            <router-link
              :to="link"
              :class="{ 'btn--disabled': pool > 0 }"
              class="btn btn--primary"
            >Continue</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
export default {
  name: "EmphasisSlider",
  components: { VueSlider },
  props: {
    section: Number,
    link: String
  },
  created: function() {},
  mounted: function() {
    this.initValues();
  },
  methods: {
    initValues() {
      this.vueRoot.userEmphasis[
        this.section
      ].leadershipLevel = this.vueRoot.selectedLevel.value;
      this.vueRoot.userEmphasis[
        this.section
      ].competency = this.vueRoot.userCompetencies[
        this.section
      ].data.competencySlug;

      this.selectDataSet();
      for (var i = 0; i < this.questionData.length; i++) {
        if (this.vueRoot.userEmphasis[this.section].ratings[i] == null) {
          this.vueRoot.userEmphasis[this.section].ratings[i] = 1;
        }
      }
      this.updatePool();
    },

    // Selects the proper data set based on leadership level and top competencies
    selectDataSet() {
      let focusCompetency = this.vueRoot.userCompetencies[this.section].data
        .competencySlug;
      let focusLevel = this.vueRoot.selectedLevel.value;
      this.questionData = this.vueRoot.staticData[focusLevel][
        focusCompetency
      ].competencyItems;
    },

    // Calculates the initial points, 3 per question
    countPool() {
      return (this.pool = 3 * this.questionData.length);
    },

    // Updates the pool after point distribution
    updatePool() {
      let total = 0;
      for (var i = 0; i < this.questionData.length; i++) {
        total = total + this.vueRoot.userEmphasis[this.section].ratings[i];
      }
      this.pool = this.countPool() - total;
    },

    // Verifies and adjusts the slider value on update to fit the pool
    adjustToPointBounds(scorePool, index) {
      this.updatePool();

      if (this.pool < 0) {
        this.vueRoot.userEmphasis[scorePool].ratings[index] -= 1;
        this.adjustToPointBounds(scorePool, index);
      }
    }
  },
  computed: {
    vueRoot() {
      return this.$root;
    }
  },
  data: function() {
    return {
      pool: null,
      marks: [1, 2, 3, 4, 5],
      questionData: null
    };
  }
};
</script>
