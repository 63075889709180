<template>
  <div class="competency-sort">
    <draggable
      class="list-group list-container"
      tag="ul"
      :list="vueRoot.userCompetencies"
      group="competencies"
      v-bind="dragOptions"
      filter=".fa-info-circle"
      preventOnFilter: false      
      @start="drag = true"
      @end="drag = false"
      @update="updateOrder"
    >
      <transition-group type="transition">
        <li class="list-group-item" v-for="element in vueRoot.userCompetencies" :key="element.order">
          <span class="list-group-item__indicator"></span>
          <span class="list-group-item__title">{{ element.data.competencyTitle }}</span>
          <span class="list-group-item__divider">|</span>
          <span class="list-group-item__description">{{ element.data.competencyDescription }}</span>
          <font-awesome-icon icon="info-circle" :content="element.data.competencyDescription" v-tippy="{ placement : 'right', trigger: 'click', arrow: true }" />
        </li>
      </transition-group>
    </draggable>
    <!-- <draggable
      v-if="vueRoot.competencyList.length !== 0"
      class="list-group list-stack"
      tag="ul"
      :list="vueRoot.competencyList"
      group="competencies"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
      @update="updateOrder"
    >
      <transition-group type="transition">
        <li class="list-group-item" v-for="element in vueRoot.competencyList" :key="element.order">
          <span class="list-group-item__indicator"></span>
          <span class="list-group-item__title">{{ element.data.competencyTitle }}</span>
          <span class="list-group-item__divider">|</span>
          <span class="list-group-item__description">{{ element.data.competencyDescription }}</span>
        </li>
      </transition-group>
    </draggable> -->
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  computed: {
    dragOptions() {
      return {
        animation: 150,
        disabled: false,
        ghostClass: "ghost"
      };
    },
    vueRoot() {
      return this.$root;
    }
  },
  data() {
    return {
    };
  },
  methods: {
    updateOrder() {
      // Reset values in case history change
      this.vueRoot.userEmphasis[0].ratings = [];
      this.vueRoot.userEmphasis[1].ratings = [];
      this.vueRoot.userEmphasis[2].ratings = [];
    }
  }
};
</script>