<template>
  <header class="app-header">
    <div class="row">
      <div class="col-xs-3 y--auto x--left">
        <a class="nav--back nav-item" @click="$router.go(-1)" :class="{ 'nav-item--disabled': currentPath == '/' || currentPath == '/report' }">
          <font-awesome-icon icon="chevron-left"/>
        </a>
      </div>
      <div class="col-xs-6 branding text--center y--auto x--center">
        <span class="header-title">Competency compass</span>
       <!--  <img src="../assets/logo_mini.png" class="logo--mini" alt="Competency Compass"> -->
      </div>
      <div class="col-xs-3 info-wrap text--right y--auto x--right">
        <font-awesome-icon
          @click="vueRoot.showModal"
          icon="info-circle"
          class="nav-item"
          :class="{ 'nav-item--disabled': currentPath == '/' || currentPath == '/selection' || currentPath == '/report' }"
        />
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "AppHeader",
  computed: {
    vueRoot() {
      return this.$root;
    },
    currentPath() {
      return this.$route.path;
    }
  }
};
</script>