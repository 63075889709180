<template>
  <div class="app-wrap app-wrap--grey">
    <div class="content-wrap content-wrap--questions">
      <div class="question-wrap">
        <div class="question-holder">
          <div class="number">1</div>
          <div
            class="question"
          >As you were completing the sorting exercise, what were you thinking about in your current
situation?</div>
        </div>
        <textarea-autosize
          placeholder="Type something here..."
          ref="someName"
          v-model="vueRoot.userAnswers[0]"
          :min-height="30"
          :max-height="350"
        ></textarea-autosize>
      </div>
      <div class="question-wrap">
        <div class="question-holder">
          <div class="number">2</div>
          <div class="question">
            You seem to emphasise leading with a focus on
            <span
              class="teal"
            >{{vueRoot.userCompetencies[0].data.competencyKeyword}}</span>,
            <span class="teal">{{vueRoot.userCompetencies[1].data.competencyKeyword}}</span>, and
            <span class="teal">{{vueRoot.userCompetencies[2].data.competencyKeyword}}</span>. How do you feel these help you in this situation?
          </div>
        </div>
        <textarea-autosize
          placeholder="Type something here..."
          ref="someName"
          v-model="vueRoot.userAnswers[1]"
          :min-height="30"
          :max-height="350"
        ></textarea-autosize>
      </div>
      <div class="question-wrap">
        <div class="question-holder">
          <div class="number">3</div>
          <div
            class="question"
          >What do you anticipate will change for you in the next 12 months?</div>
        </div>
        <textarea-autosize
          placeholder="Type something here..."
          ref="someName"
          v-model="vueRoot.userAnswers[2]"
          :min-height="30"
          :max-height="350"
        ></textarea-autosize>
      </div>
    </div>

    <div class="cta-wrap">
      <div class="row">
        <div class="col-xs-12 text--center">
          <router-link
            to="/emphasis/1"
            :class="{ 'btn--disabled': vueRoot.userAnswers[0] == '' || vueRoot.userAnswers[1] == '' || vueRoot.userAnswers[2] == '' }"
            class="btn btn--primary"
          >Continue</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LevelSelection",
  components: {},
  mounted: function() {
    this.vueRoot.checkProgression(1);
  },
  computed: {
    vueRoot() {
      return this.$root;
    }
  },
  data: function() {
    return {};
  }
};
</script>
