<template>
  <div class="app-wrap">
    <div class="row">
      <div class="col-xs-6">
        <img src="../assets/gov.png" class="top-logo" alt />
      </div>
      <div class="col-xs-6 text--right">
        <img src="../assets/hudson.png" class="top-logo" alt />
      </div>
    </div>
    <div class="content-wrap content-wrap--mid text--center">
      <div>
        <img
          src="../assets/logo_compass_white.png"
          class="competency-compass"
          alt="Competency Compass"
        />
        <h1>Competency compass</h1>
      </div>
      <p>Welcome to the Competency compass!</p>
      <p>
        This is a tool to help you reflect on your leadership skills and arm you with insights to inform
        conversations with your supervisor about your development.
      </p>
      <p>The compass will guide you through a series of self-reflection exercises to help you:</p>
      <ul>
        <li>
          understand the
          <a
            href="https://www.forgov.qld.gov.au/leadership-competencies-queensland"
            target="_blank"
          >Leadership competencies for Queensland</a> you’re currently prioritising in your role
        </li>
        <li>consider how these priorities may change in the future</li>
        <li>explore the behaviours that you most closely focus on</li>
        <li>compare these reflections to your peers.</li>
      </ul>
      <p>
        Based on your responses to the activities, the compass will provide you with a summary report to
        help guide your development choices, conversations with your supervisor, and where you may like
        your career to go.
      </p>
    </div>
    <div class="cta-wrap">
      <div class="row">
        <div class="col-xs-12 text--center">
          <router-link to="/selection" class="btn btn--primary">Let's get started</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "app",
  methods: {
  },
  data: function() {
    return {

    };
  }
};
</script>