<template>
  <div class="report-slider">
    <div class="question">{{question}}</div>
    <div class="band">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <div
        class="value value--my"
        v-bind:style="{ left: calculatePosition(rating) }"
        content="Your score"
        v-tippy="{ placement : 'top', arrow: true }"
      ></div>
      <div
        v-if="average"
        class="value value--average"
        v-bind:style="{ left: calculatePosition(average) }"
        content="Average score"
        v-tippy="{ placement : 'bottom', arrow: true }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportSlider",
  props: {
    rating: Number,
    average: Number,
    question: String
  },
  created: function() {},
  mounted: function() {},
  methods: {
    calculatePosition(point) {
      let position = point * 25 - 25 + "%";
      return position;
    }
  },
  data() {
    return {
    };
  },
};
</script>
