<template>
  <EmphasisSlider :section="1" link="/emphasis/3"></EmphasisSlider>
</template>
<script>
import EmphasisSlider from "../components/EmphasisSlider.vue";
export default {
  name: "Emphasis2",
  components: { EmphasisSlider },
  created: function() {},
  mounted: function() {},
  methods: {},
  computed: {},
  data: function() {
    return {};
  }
};
</script>