import Vue from "vue";
import VueRouter from "vue-router";

import LandingPage from "./templates/LandingPage.vue";
import LevelSelection from "./templates/LevelSelection.vue";
import CompetencySort from "./templates/CompetencySort.vue";
import QuestionsAndAnswers from "./templates/QuestionsAndAnswers.vue";
import Emphasis1 from "./templates/Emphasis1.vue";
import Emphasis2 from "./templates/Emphasis2.vue";
import Emphasis3 from "./templates/Emphasis3.vue";
import Report from "./templates/Report.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes: [
    /*{
      path: "*",
      redirect: "/login"
    },
    {
      path: "/",
      redirect: "/login"
    },*/
    {
      path: "/",
      name: "LandingPage",
      component: LandingPage,
      meta: {
        //requiresAuth: true
      }
    },
    {
      path: "/selection",
      name: "LevelSelection",
      component: LevelSelection
    },
    {
      path: "/competencies",
      name: "CompetencySort",
      component: CompetencySort
    },
    {
      path: "/questions",
      name: "QuestionsAndAnswers",
      component: QuestionsAndAnswers
    },
    {
      path: "/emphasis/1",
      name: "Emphasis1",
      component: Emphasis1
    },
    {
      path: "/emphasis/2",
      name: "Emphasis2",
      component: Emphasis2
    },
    {
      path: "/emphasis/3",
      name: "Emphasis3",
      component: Emphasis3
    },
    {
      path: "/report",
      name: "Report",
      component: Report
    }   
  ]
});

/*
router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next("login");
  else if (!requiresAuth && currentUser) next("home");
  else next();
});
*/

export default router;
