export const competencyTable = {
  individualContributor: {
    leadsStrategically: {
      competencyTitle: "Leads Strategically",
      leadershipArea: "Vision",
      competencyDescription:
        "Thinks critically and acts on the broader purpose of the system",
      competencyItems: [
        "Recognising how organisational events and issues impact on the work of the team",
        "Recognising and articulating how my own work directly contributes to the organisation’s vision and community outcomes",
        "Prioritising projects and tasks efficiently, in line with team commitments",
        "Seeking and sharing customer feedback to support the refinement of planned activities"
      ]
    },
    stimulatesIdeasAndInnovation: {
      competencyTitle: "Stimulates ideas and innovation",
      leadershipArea: "Vision",
      competencyDescription:
        "Gathers insights and embraces new ideas and innovation to inform future practice",
      competencyItems: [
        "Challenging the status quo by asking questions to bridge information gaps and clearly define new opportunities",
        "Using data and leveraging technology to achieve greater efficiencies",
        "Sharing and seeking creative ideas, suggestions and data to inform the delivery of services",
        "Actively participating in the design of new solutions and new ways of working"
      ]
    },
    leadsChangeInComplexEnvironments: {
      competencyTitle: "Leads change in complex environments",
      leadershipArea: "Vision",
      competencyDescription:
        "Embraces change and leads with focus and optimism in an environment of complexity and ambiguity",
      competencyItems: [
        "Demonstrating flexibility to changing expectations by proactively adapting my own approach to reflect new requirements",
        "Responding constructively to periods of uncertainty by role modelling positivity and work focus",
        "Actively participating in change engagement activities and openly supporting the intended outcomes"
      ]
    },
    makesInsightfulDecisions: {
      competencyTitle: "Makes insightful decisions",
      leadershipArea: "Vision",
      competencyDescription:
        "Makes considered, ethical and courageous decisions based on insight into the broader context",
      competencyItems: [
        "Gaining insight into issues by analysing the available information in a critical manner",
        "Demonstrating courage in making decisions, even when under pressure",
        "Accepting decision making responsibility and demonstrating judgement about when to escalate issues",
        "Remaining composed during high-stakes situations",
        "Clearly describing the rationale behind decisions and working with others to recognise potential bias"
      ]
    },
    developsAndMobilisesTalent: {
      competencyTitle: "Develops and mobilises talent",
      leadershipArea: "Results",
      competencyDescription:
        "Strengthens and mobilises the unique talents and capabilities of the workforce",
      competencyItems: [
        "Leveraging my own strengths and acting as a mentor, role model and sounding board for others",
        "Initiating conversations around career development and readily sharing knowledge and experience with others",
        "Actively participating in team development opportunities, such as lessons learned conversations",
        "Providing and appropriately passing on feedback to others",
        "Taking the initiative to collaborate with people from a diverse range of backgrounds, experiences and expertise"
      ]
    },
    buildsEnduringRelationships: {
      competencyTitle: "Builds enduring relationships",
      leadershipArea: "Results",
      competencyDescription:
        "Builds and sustains relationships to enable the collaborative delivery of customer-focused outcomes",
      competencyItems: [
        "Communicating in a clear, succinct and deliberate manner, adjusting the message so that it resonates with different stakeholders",
        "Supporting others by providing timely information within my own area of expertise in an articulate and non-technical manner",
        "Listening attentively and proactively seeking to understand others’ needs by asking questions and clarifying",
        "Recognising the broad value and contribution that diversity of viewpoints brings to the team and organisation",
        "Building rapport and establishing strong and mutually beneficial connections"
      ]
    },
    inspiresOthers: {
      competencyTitle: "Inspires others",
      leadershipArea: "Results",
      competencyDescription:
        "Inspires others by driving clarity, engagement and a sense of purpose",
      competencyItems: [
        "Recognising and reflecting on the purpose and impact of my work",
        "Actively participating in organisational initiatives",
        "Promoting the team’s work through clear and compelling communication",
        "Celebrating achievements in my own and others’ work"
      ]
    },
    drivesAccountabilityAndOutcomes: {
      competencyTitle: "Drives accountability and outcomes",
      leadershipArea: "Results",
      competencyDescription:
        "Demonstrates accountability for the execution and quality of results through professionalism, persistence and transparency",
      competencyItems: [
        "Contributing to the development of team objectives and recognising my own role in achieving results",
        "Welcoming challenges in the delivery of work and demonstrating persistence in working through obstacles",
        "Demonstrating careful planning and organisation to achieve results",
        "Implementing reporting mechanisms with honest recognition of progress against goals"
      ]
    },
    fostersHealthyAndInclusiveWorkplaces: {
      competencyTitle: "Fosters healthy and inclusive workplaces",
      leadershipArea: "Accountability",
      competencyDescription:
        "Fosters an inclusive workplace where health, safety and wellbeing is promoted and prioritised",
      competencyItems: [
        "Demonstrating personal responsibility for the health, safety and wellbeing of myself and others",
        "Demonstrating respect for others, taking the time to connect, check in and show an interest in their wellbeing",
        "Enhancing safety and inclusion in the team by sharing ideas and participating in initiatives",
        "Considering the physical environment and carefully undertaking tasks with the safety and wellbeing of others in mind",
        "Demonstrating openness to diversity and supporting practices that enable all individuals to participate to their fullest ability"
      ]
    },
    pursuesContinuousGrowth: {
      competencyTitle: "Pursues continuous growth",
      leadershipArea: "Accountability",
      competencyDescription:
        "Pursues opportunities for growth through agile learning, and development of self-awareness",
      competencyItems: [
        "Demonstrating insight into personal strengths and areas for development and taking account of these when acting",
        "Actively seeking feedback and modifying my approach to enhance my own effectiveness",
        "Taking personal accountability for learning, and eagerly pursuing new knowledge and experiences"
      ]
    },
    demonstratesSoundGovernance: {
      competencyTitle: "Demonstrates sound governance",
      leadershipArea: "Accountability",
      competencyDescription:
        "Maintains a high standard of practice through governance and risk management",
      competencyItems: [
        "Demonstrating respect and appreciation for legislation and policy frameworks by consistently operating to key standards",
        "Upholding integrity through responsible management and use of processes and resources",
        "Analysing information to proactively identify risks to the team’s planned activities",
        "Evaluating possible solutions and taking appropriate steps to mitigate risks"
      ]
    }
  },
  teamLeader: {
    leadsStrategically: {
      competencyTitle: "Leads Strategically",
      leadershipArea: "Vision",
      competencyDescription:
        "Thinks critically and acts on the broader purpose of the system",
      competencyItems: [
        "Determining the connection between organisational events and issues, and their impact on the team’s work",
        "Recognising and articulating how the team’s work contributes to the organisation’s vision and community outcomes",
        "Working with the team to organise work priorities so they deliver on broader organisational commitments",
        "Seeking and leveraging stakeholder feedback to steer and adjust plans, and encouraging others to do the same"
      ]
    },
    stimulatesIdeasAndInnovation: {
      competencyTitle: "Stimulates ideas and innovation",
      leadershipArea: "Vision",
      competencyDescription:
        "Gathers insights and embraces new ideas and innovation to inform future practice",
      competencyItems: [
        "Challenging the status quo, asking questions and seeking out new models and developments that may inform the team’s approach",
        "Supporting the team to generate actionable insights from data and empowering the use of technology to drive new efficiencies",
        "Driving new thinking by encouraging others to share and debate ideas and data-driven, creative solutions",
        "Providing time, support and resources to the team to test and refine new ways of doing things"
      ]
    },
    leadsChangeInComplexEnvironments: {
      competencyTitle: "Leads change in complex environments",
      leadershipArea: "Vision",
      competencyDescription:
        "Embraces change and leads with focus and optimism in an environment of complexity and ambiguity",
      competencyItems: [
        "Empowering others to understand and embrace change by communicating openly about the rationale and intended outcomes",
        "Remaining accessible and optimistic, and keeping the team focused through periods of uncertainty",
        "Providing individualised support and consulting with others to support them through change processes"
      ]
    },
    makesInsightfulDecisions: {
      competencyTitle: "Makes insightful decisions",
      leadershipArea: "Vision",
      competencyDescription:
        "Makes considered, ethical and courageous decisions based on insight into the broader context",
      competencyItems: [
        "Analysing available data and supporting others to identify essential information to uncover the cause of issues",
        "Role modelling courage in making decisions under pressure or in the absence of complete information",
        "Empowering collaborative decision making in the team",
        "Remaining composed during high-stakes situations, supporting the team to recognise gaps in information and seeking advice to enable a prompt and considered response",
        "Supporting others to understand the strengths, potential bias and missing considerations in their decision making process"
      ]
    },
    developsAndMobilisesTalent: {
      competencyTitle: "Develops and mobilises talent",
      leadershipArea: "Results",
      competencyDescription:
        "Strengthens and mobilises the unique talents and capabilities of the workforce",
      competencyItems: [
        "Recognising the unique strengths, areas for development and aspirations of direct reports",
        "Implementing development plans with direct reports to address skill and knowledge gaps and enhance the capabilities they require for current and future roles",
        "Assisting others to identify and participate in flexible learning opportunities to stretch their skills, knowledge and experience",
        "Encouraging self-reflection, and providing clear, timely and accurate feedback on progress against agreed goals",
        "Enhancing team capacity by bringing together people of diverse discipline, background and perspectivee"
      ]
    },
    buildsEnduringRelationships: {
      competencyTitle: "Builds enduring relationships",
      leadershipArea: "Results",
      competencyDescription:
        "Builds and sustains relationships to enable the collaborative delivery of customer-focused outcomes",
      competencyItems: [
        "Demonstrating awareness of others by tailoring messages so they resonate with stakeholders of diverse interests and backgrounds",
        "Initiating two-way collaborations by providing information and advice in a timely, proactive and non-technical manner, supporting the team to do the same",
        "Achieving mutually beneficial outcomes by listening intently and asking questions to understand the perspectives of others",
        "Actively leveraging the power of diversity by encouraging the unique contributions and viewpoints in planning and decision making",
        "Setting up connections for the team across the organisation to drive collaborative delivery of services"
      ]
    },
    inspiresOthers: {
      competencyTitle: "Inspires others",
      leadershipArea: "Results",
      competencyDescription:
        "Inspires others by driving clarity, engagement and a sense of purpose",
      competencyItems: [
        "Charting a clear direction for the team by consistently communicating the greater meaning and impact of the work undertaken",
        "Generating active engagement in key initiatives through clear, enthusiastic and compelling communication",
        "Bringing the team together to define and promote its identity and contributions to the organisation",
        "Encouraging generosity through celebrating the contribution of others"
      ]
    },
    drivesAccountabilityAndOutcomes: {
      competencyTitle: "Drives accountability and outcomes",
      leadershipArea: "Results",
      competencyDescription:
        "Demonstrates accountability for the execution and quality of results through professionalism, persistence and transparency",
      competencyItems: [
        "Working with the team to develop shared objectives and clear indicators for success",
        "Role modelling persistence and supporting others to work through obstacles in the delivery of projects",
        "Driving autonomy in the team by empowering others to take ownership for the delivery and quality of outcomes achieved",
        "Implementing reporting mechanisms to oversee the work of the team and provide appropriate levels of input"
      ]
    },
    fostersHealthyAndInclusiveWorkplaces: {
      competencyTitle: "Fosters healthy and inclusive workplaces",
      leadershipArea: "Accountability",
      competencyDescription:
        "Fosters an inclusive workplace where health, safety and wellbeing is promoted and prioritised",
      competencyItems: [
        "Promoting personal responsibility for the realisation of a healthy workplace through regular communication, feedback and sharing of observations and outcomes",
        "Demonstrating respect for others, taking the time to connect, check in and show an interest in their wellbeing",
        "Encouraging others to proactively identify, remedy and enhance safe and inclusive practices",
        "Ensuring the physical environment and planning of tasks are optimised to support team wellbeing",
        "Demonstrating flexibility and openness to the diversity in the team, supporting approaches that enable all individuals to participate to their fullest ability"
      ]
    },
    pursuesContinuousGrowth: {
      competencyTitle: "Pursues continuous growth",
      leadershipArea: "Accountability",
      competencyDescription:
        "Pursues opportunities for growth through agile learning, and development of self-awareness",
      competencyItems: [
        "Developing awareness of strengths and development needs, and reflecting on the effectiveness of my own behaviour and approach",
        "Acting as a role model for the team by actively seeking feedback and communicating openly about learnings",
        "Embracing opportunities to expand knowledge and experience through networks, new assignments and development avenues"
      ]
    },
    demonstratesSoundGovernance: {
      competencyTitle: "Demonstrates sound governance",
      leadershipArea: "Accountability",
      competencyDescription:
        "Maintains a high standard of practice through governance and risk management",
      competencyItems: [
        "Supporting others to embrace and operate within legislative and policy frameworks that guide everyday practice",
        "Promoting integrity in the management and use of processes and resources, ensuring others have the information and training they need for successful implementation",
        "Analysing data and trends to identify risks to project activities and empowering others to incorporate risk management into planning",
        "Working with the team to evaluate options and develop appropriate plans to mitigate risks"
      ]
    }
  },
  programLeader: {
    leadsStrategically: {
      competencyTitle: "Leads Strategically",
      leadershipArea: "Vision",
      competencyDescription:
        "Thinks critically and acts on the broader purpose of the system",
      competencyItems: [
        "Viewing situations through a range of perspectives, analysing multiple sources of information to recognise the broader implications of issues",
        "Clearly articulating the program’s role in achieving the organisational vision, working with others to determine program strategy, parameters and purpose",
        "Demonstrating a forward focus, engaging partners in translating the strategic plan into concrete actions",
        "Continuously refining the prioritisation of program commitments through stakeholder feedback and insight"
      ]
    },
    stimulatesIdeasAndInnovation: {
      competencyTitle: "Stimulates ideas and innovation",
      leadershipArea: "Vision",
      competencyDescription:
        "Gathers insights and embraces new ideas and innovation to inform future practice",
      competencyItems: [
        "Monitoring changes in the environment through questions, observations and community insights to generate a deep understanding of program improvement opportunities",
        "Facilitating the early adoption of emerging digital technologies and data to deliver greater efficiency in services",
        "Encouraging mutual exchange of ideas, data and information, engaging partners as active contributors in program design",
        "Bringing people together to build new solutions through testing and refinement"
      ]
    },
    leadsChangeInComplexEnvironments: {
      competencyTitle: "Leads change in complex environments",
      leadershipArea: "Vision",
      competencyDescription:
        "Embraces change and leads with focus and optimism in an environment of complexity and ambiguity",
      competencyItems: [
        "Demonstrating flexibility in response to change and supporting the workforce to embrace new expectations through clarity of the intended outcomes",
        "Driving workforce resilience by supporting others to remain goal and outcome focused when confronted with enduring uncertainty",
        "Inspiring others to embrace change initiatives, overcoming barriers and resistance through proactive consultation and engagement"
      ]
    },
    makesInsightfulDecisions: {
      competencyTitle: "Makes insightful decisions",
      leadershipArea: "Vision",
      competencyDescription:
        "Makes considered, ethical and courageous decisions based on insight into the broader context",
      competencyItems: [
        "Identifying the underlying cause of issues through considered analysis and integration of various pieces of data and insight",
        "Role modelling courage in making decisions, providing advice and a clear rationale even with conflicting or incomplete information",
        "Ensuring the right delegative structures exist to empower decision making at the appropriate level",
        "Being a stabilising influence in challenging situations, supporting others to promptly identify considerations, impacts and risks to plan a response",
        "Promptly identifying the potential for bias and coaching others through decision making processes"
      ]
    },
    developsAndMobilisesTalent: {
      competencyTitle: "Develops and mobilises talent",
      leadershipArea: "Results",
      competencyDescription:
        "Strengthens and mobilises the unique talents and capabilities of the workforce",
      competencyItems: [
        "Recognising the unique talents and aspirations of the workforce and enhancing capability through targeted development activities",
        "Considering the future skills required to deliver program objectives and implementing strategies to support succession of critical roles and capabilities",
        "Facilitating opportunities for learning and stretching assignments across programs to build capability and agility in the organisation",
        "Coaching others, encouraging critical reflection, ownership for problems and discovery of self-lead solutions",
        "Enhancing program capacity and community outcomes by building teams of diverse discipline, background and perspective"
      ]
    },
    buildsEnduringRelationships: {
      competencyTitle: "Builds enduring relationships",
      leadershipArea: "Results",
      competencyDescription:
        "Builds and sustains relationships to enable the collaborative delivery of customer-focused outcomes",
      competencyItems: [
        "Reading situations and anticipating needs and areas of interest of others, to tailor messages accordingly",
        "Strengthening partnerships by providing proactive advice, and supporting others to share relevant information in a tactful and articulate manner",
        "Articulating complex concepts and working towards win-win outcomes by presenting a clear and convincing rationale",
        "Actively leveraging the power of diversity by encouraging the inclusion of diverse viewpoints and perspectives in program development",
        "Proactively steering team collaborations across boundaries to achieve joint delivery of program outcomes"
      ]
    },
    inspiresOthers: {
      competencyTitle: "Inspires others",
      leadershipArea: "Results",
      competencyDescription:
        "Inspires others by driving clarity, engagement and a sense of purpose",
      competencyItems: [
        "Distilling and communicating the vision authentically to connect program objectives to the broader priorities",
        "Achieving active participation and buy-in to the program by communicating with presence and pride for its intended outcome",
        "Supporting others to act as program representatives, achieving sustained stakeholder engagement through promotion of its benefits",
        "Fostering a culture of celebration by visibly sharing team successes and supporting others to do the same"
      ]
    },
    drivesAccountabilityAndOutcomes: {
      competencyTitle: "Drives accountability and outcomes",
      leadershipArea: "Results",
      competencyDescription:
        "Demonstrates accountability for the execution and quality of results through professionalism, persistence and transparency",
      competencyItems: [
        "Working collaboratively to develop clear and shared objectives that inspire collective ownership for delivery of quality outcomes",
        "Role modelling persistence through program setbacks, empowering others to source alternate strategies to overcome issues",
        "Driving ownership and empowering others to independently manage and report on their portfolio responsibilities",
        "Using feedback mechanisms to stay in touch with program progress and directing energy and time towards matters of priority"
      ]
    },
    fostersHealthyAndInclusiveWorkplaces: {
      competencyTitle: "Fosters healthy and inclusive workplaces",
      leadershipArea: "Accountability",
      competencyDescription:
        "Fosters an inclusive workplace where health, safety and wellbeing is promoted and prioritised",
      competencyItems: [
        "Promoting personal and team responsibility for the realisation of a healthy and safe workplace",
        "Building the confidence and trust of others, connecting with them personally to understand and consider their wellbeing",
        "Engaging others in open dialogue and identification of initiatives to promote safe and inclusive practice",
        "Taking steps to optimise the environment and facilitate the appropriate resources to promote staff wellbeing",
        "Contributing to the development of inclusive systems and practices that allow all individuals to participate to their fullest ability"
      ]
    },
    pursuesContinuousGrowth: {
      competencyTitle: "Pursues continuous growth",
      leadershipArea: "Accountability",
      competencyDescription:
        "Pursues opportunities for growth through agile learning, and development of self-awareness",
      competencyItems: [
        "Building strong self-awareness of leadership strengths and derailers, and critically reflecting on my own performance, practice and impact as an organisational leader",
        "Visibly role modelling a curiosity and passion for learning by seeking feedback and communicating openly about strengths and development goals",
        "Broadening knowledge and perspective by seeking new collaborations and experiences and participating in development initiatives"
      ]
    },
    demonstratesSoundGovernance: {
      competencyTitle: "Demonstrates sound governance",
      leadershipArea: "Accountability",
      competencyDescription:
        "Maintains a high standard of practice through governance and risk management",
      competencyItems: [
        "Role modelling impartiality and ensuring legislative and regulatory frameworks are applied effectively",
        "Acting in the interests of the community and organisation through the cost-effective commissioning of goods and services, using best practice procurement processes and appropriate supplier relationships",
        "Working closely with partners to analyse trends and forecast long-term risks to program outcomes",
        "Proactively facilitating collaborations to develop contingency plans in response to identified risks"
      ]
    }
  },
  executive: {
    leadsStrategically: {
      competencyTitle: "Leads Strategically",
      leadershipArea: "Vision",
      competencyDescription:
        "Thinks critically and acts on the broader purpose of the system",
      competencyItems: [
        "Anticipating the consequences of public policy on the organisation and community, and engaging in high level critical thinking to identify the links and implications between complex issues",
        "Integrating government and organisational priorities and values, and the voice of the community to develop and articulate a compelling vision that provides clear direction for the sector’s future",
        "Adopting a sector leadership role, working collaboratively with partners to translate the strategy into meaningful action",
        "Fostering the exchange of ideas and perspectives to develop a 3–5 year strategy that brings to life government objectives"
      ]
    },
    stimulatesIdeasAndInnovation: {
      competencyTitle: "Stimulates ideas and innovation",
      leadershipArea: "Vision",
      competencyDescription:
        "Gathers insights and embraces new ideas and innovation to inform future practice",
      competencyItems: [
        "Scanning the environment, drawing on information and alternative viewpoints, asking questions and monitoring information channels to understand emerging issues that are important to the sector, organisation and community",
        "Driving curiosity in the system, leveraging data and harnessing emerging digital technologies to deliver efficient services",
        "Stimulating genuine debate to better define and address system challenges by actively sharing ideas and data",
        "Building and mobilising cross-sector collaborations to prototype and test innovative solutions"
      ]
    },
    leadsChangeInComplexEnvironments: {
      competencyTitle: "Leads change in complex environments",
      leadershipArea: "Vision",
      competencyDescription:
        "Embraces change and leads with focus and optimism in an environment of complexity and ambiguity",
      competencyItems: [
        "Responding flexibly to changing government, organisational and community expectations, systematically introducing required changes",
        "Maintaining an optimistic outlook and encouraging the same in others, focusing on organisational strategy and objectives through periods of uncertainty",
        "Leading change agendas, generating workforce readiness through timely communications and strategic support mechanisms that allow people to thrive"
      ]
    },
    makesInsightfulDecisions: {
      competencyTitle: "Makes insightful decisions",
      leadershipArea: "Vision",
      competencyDescription:
        "Makes considered, ethical and courageous decisions based on insight into the broader context",
      competencyItems: [
        "Identifying the underlying cause of systemic and organisational issues through considered analysis of data, patterns and insights",
        "Role modelling courage in making decisions and providing frank and fearless advice even in ambiguous situations, in the absence of complete information",
        "Ensuring the right delegative structures exist to empower decision making at the appropriate level",
        "Being a stabilising influence, quickly discerning key implications for government, the organisation and community, to respond comprehensively in crisis or high-stakes situations",
        "Building awareness of decision making biases and encouraging others to proactively seek advice to protect the organisation and community’s long-term interests"
      ]
    },
    developsAndMobilisesTalent: {
      competencyTitle: "Develops and mobilises talent",
      leadershipArea: "Results",
      competencyDescription:
        "Strengthens and mobilises the unique talents and capabilities of the workforce",
      competencyItems: [
        "Developing organisational capability by dedicating time to understand the diverse goals and capabilities of others, empowering them to own and participate in creative ways to expand their knowledge, gain exposure, and new experiences across the system",
        "Considering the future skills, capabilities and diverse needs of the community and workforce to set the direction for talent management to deliver government objectives",
        "Actively mobilising talent across the sector to build the agility, responsiveness and capacity of the workforce",
        "Contributing my own expertise for the benefit of the organisation, acting as a mentor, coach and sponsor of capability development initiatives",
        "Strengthening organisational capacity by mobilising teams of diverse discipline, background and perspective"
      ]
    },
    buildsEnduringRelationships: {
      competencyTitle: "Builds enduring relationships",
      leadershipArea: "Results",
      competencyDescription:
        "Builds and sustains relationships to enable the collaborative delivery of customer-focused outcomes",
      competencyItems: [
        "Reading contentious and sensitive situations and responding appropriately to manage relationships",
        "Proactively informing and advising partners on matters of interest",
        "Acting as a skilful negotiator, using compelling arguments and evidence to persuade stakeholders toward mutually beneficial outcomes",
        "Actively encouraging and supporting the inclusion of diverse viewpoints of colleagues, community and partners in policy and strategy development",
        "Removing systemic barriers to collaboration and implementing strategies to build strong and enduring alliances for joint delivery of community outcomes"
      ]
    },
    inspiresOthers: {
      competencyTitle: "Inspires others",
      leadershipArea: "Results",
      competencyDescription:
        "Inspires others by driving clarity, engagement and a sense of purpose",
      competencyItems: [
        "Cascading the vision through clear and authentic communication to inspire collective ownership for government priorities",
        "Conveying passion and conviction through influential messages that achieve active engagement in initiatives",
        "Appropriately promoting the organisation’s position and creating opportunities for others to do the same",
        "Building engagement through recognition and celebration, facilitating opportunities for others to represent the organisation and share its stories of success"
      ]
    },
    drivesAccountabilityAndOutcomes: {
      competencyTitle: "Drives accountability and outcomes",
      leadershipArea: "Results",
      competencyDescription:
        "Demonstrates accountability for the execution and quality of results through professionalism, persistence and transparency",
      competencyItems: [
        "Working with partners to establish ambitious objectives and clear performance standards that inspire collective ownership",
        "Actively seeking cross-sector collaborations to overcome issues and direct resources towards matters of priority",
        "Driving ownership and empowering others to lead and report on their area of responsibility with complete transparency of outcomes",
        "Keeping a finger on the pulse through feedback structures to anticipate and respond promptly to emerging issues, shifting the investment of time and energy as required"
      ]
    },
    fostersHealthyAndInclusiveWorkplaces: {
      competencyTitle: "Fosters healthy and inclusive workplaces",
      leadershipArea: "Accountability",
      competencyDescription:
        "Fosters an inclusive workplace where health, safety and wellbeing is promoted and prioritised",
      competencyItems: [
        "Fostering a workplace culture where all individuals share a personal responsibility for promoting the physical and psychological health and safety of others",
        "Building confidence and trust as a leader who respects and prioritises people’s wellbeing",
        "Visibly sponsoring initiatives that build awareness and motivate the workforce to improve safety and inclusive practice",
        "Optimising the work environment, work design, and culture to enable a positive and healthy workplace",
        "Anticipating the growing diversity of workforce interests and needs, setting policy direction to position the organisation as an employer of choice"
      ]
    },
    pursuesContinuousGrowth: {
      competencyTitle: "Pursues continuous growth",
      leadershipArea: "Accountability",
      competencyDescription:
        "Pursues opportunities for growth through agile learning, and development of self-awareness",
      competencyItems: [
        "Building strong self-awareness of leadership strengths and derailers and critically reflecting on my own performance, practice and impact as a sector leader",
        "Visibly role modelling a curiosity and passion for life-long learning by seeking feedback and communicating openly about strengths and development goals",
        "Actively seeking opportunities for involvement in cross-sector and cross-government initiatives to extend experience and gain exposure to new environments, perspectives and ways of working"
      ]
    },
    demonstratesSoundGovernance: {
      competencyTitle: "Demonstrates sound governance",
      leadershipArea: "Accountability",
      competencyDescription:
        "Maintains a high standard of practice through governance and risk management",
      competencyItems: [
        "Role modelling impartiality and inspiring a culture that respects and appreciates policy frameworks and governance",
        "Operating as a trusted community steward, driving value for money services and using best practice procurement processes to enable appropriate management of public money and resources",
        "Drawing on data forecasts and working closely with partners to identify and predict the global factors that may impact strategy realisation",
        "Fostering cross-sector collaborations to assess risk and develop contingencies and options to protect the sustainable delivery of services"
      ]
    }
  },
  chiefExecutive: {
    leadsStrategically: {
      competencyTitle: "Leads Strategically",
      leadershipArea: "Vision",
      competencyDescription:
        "Thinks critically and acts on the broader purpose of the system",
      competencyItems: [
        "Recognising the links and connections between complex system issues, promptly discerning the key implications for government, the organisation and community",
        "Integrating government objectives, and the voice of the community, to develop, articulate and realise a compelling vision for the system and the organisation",
        "Adopting a stewardship role, enabling sector leaders to translate the strategy into meaningful action",
        "Establishing clear priorities to deliver the government’s objectives for the community and working collaboratively as a system leader to develop a robust 5+ year strategy"
      ]
    },
    stimulatesIdeasAndInnovation: {
      competencyTitle: "Stimulates ideas and innovation",
      leadershipArea: "Vision",
      competencyDescription:
        "Gathers insights and embraces new ideas and innovation to inform future practice",
      competencyItems: [
        "Continually scanning the global system and operating environment to identify emerging social, economic, political, environmental and technological opportunities that inform system practice",
        "Promoting a culture of innovation and the strategic use of data and technologies to stimulate new models of service delivery",
        "Continually exchanging ideas, data and information across the sector to enable discovery and debate of new ways to address system challenges",
        "Actively sponsoring cross-sector and cross-government collaborations to seize and invest in innovation opportunities"
      ]
    },
    leadsChangeInComplexEnvironments: {
      competencyTitle: "Leads change in complex environments",
      leadershipArea: "Vision",
      competencyDescription:
        "Embraces change and leads with focus and optimism in an environment of complexity and ambiguity",
      competencyItems: [
        "Leading the strategic introduction of changes across the sector, responding rapidly and purposefully to government priorities",
        "Fostering the spirit of optimism through periods of widespread change, driving a focus on outcomes",
        "Sponsoring considered change agendas and working collaboratively with others to build system readiness for emerging opportunities"
      ]
    },
    makesInsightfulDecisions: {
      competencyTitle: "Makes insightful decisions",
      leadershipArea: "Vision",
      competencyDescription:
        "Makes considered, ethical and courageous decisions based on insight into the broader context",
      competencyItems: [
        "Identifying the underlying cause of government and system issues through critical analysis of complex information",
        "Role modelling courage in decision making and providing frank and impartial advice on politically sensitive, high-priority strategic issues even in the absence of complete information",
        "Setting delegative structures that empower decision making at the appropriate level",
        "Being a stabilising influence, quickly discerning implications across the system, to enable a comprehensive response in crisis or high-stakes situations",
        "Driving probity in decision making and expecting others to draw on tangible information and advice to protect the sector and community’s long-term interests"
      ]
    },
    developsAndMobilisesTalent: {
      competencyTitle: "Develops and mobilises talent",
      leadershipArea: "Results",
      competencyDescription:
        "Strengthens and mobilises the unique talents and capabilities of the workforce",
      competencyItems: [
        "Developing organisational capability by dedicating time to understand the diverse goals and capabilities of leaders, empowering them to invest in avenues that expand their capability and impact across the system",
        "Charting the direction for talent management across the sector by considering the future skills and capabilities required to deliver on government objectives and changing community expectations",
        "Championing sector-wide talent mobility to guarantee the evolution of skill and responsiveness",
        "Prioritising investment in capability development initiatives and contributing my own expertise for the benefit of the system",
        "Strengthening system capacity by bringing together groups of diverse discipline, background and perspective"
      ]
    },
    buildsEnduringRelationships: {
      competencyTitle: "Builds enduring relationships",
      leadershipArea: "Results",
      competencyDescription:
        "Builds and sustains relationships to enable the collaborative delivery of customer-focused outcomes",
      competencyItems: [
        "Reading contentious and sensitive situations and responding appropriately to manage relationships",
        "Modelling and driving purposeful partnerships with leaders of relevant internal and external stakeholder groups, including Ministers and their staff",
        "Anticipating concerns and strategically positioning the organisation’s view on issues to achieve system outcomes",
        "Championing the development of policy and strategy through the integration of diverse voices and perspectives",
        "Steering cross-sector and cross-government collaborations to build strong and enduring alliances for joint delivery of community outcomes"
      ]
    },
    inspiresOthers: {
      competencyTitle: "Inspires others",
      leadershipArea: "Results",
      competencyDescription:
        "Inspires others by driving clarity, engagement and a sense of purpose",
      competencyItems: [
        "Cascading a vision through clear and authentic communication to inspire collective ownership for government priorities",
        "Conveying passion and conviction through influential messages that achieve significant system-wide engagement",
        "Achieving visibility across the system, appropriately promoting the organisation’s position and greater system impact",
        "Building community and system engagement by recognising and celebrating outcomes"
      ]
    },
    drivesAccountabilityAndOutcomes: {
      competencyTitle: "Drives accountability and outcomes",
      leadershipArea: "Results",
      competencyDescription:
        "Demonstrates accountability for the execution and quality of results through professionalism, persistence and transparency",
      competencyItems: [
        "Working collaboratively to establish ambitious organisational objectives that inspire collective ownership and maximise value for the community",
        "Leveraging collaborations across the system to overcome issues and mobilise resources towards matters of priority",
        "Creating a culture of accountability by empowering others to lead with complete transparency for outcomes",
        "Maintaining strategic oversight through feedback structures, investing time where the greatest impact and contribution can be achieved"
      ]
    },
    fostersHealthyAndInclusiveWorkplaces: {
      competencyTitle: "Fosters healthy and inclusive workplaces",
      leadershipArea: "Accountability",
      competencyDescription:
        "Fosters an inclusive workplace where health, safety and wellbeing is promoted and prioritised",
      competencyItems: [
        "Fostering an environment where all individuals share a personal responsibility for promoting the physical and psychological health and safety of others",
        "Building confidence and trust as a leader who respects and prioritises people’s wellbeing",
        "Leading sector-wide initiatives that build awareness and motivate the workforce to improve or maintain safe and inclusive practice",
        "Leading the development of sector-wide strategies that optimise the environment, work design and culture to enhance holistic wellness",
        "Advocating for the growing diversity of workforce interests and needs by shaping policy direction that positions the sector as an employer of choice"
      ]
    },
    pursuesContinuousGrowth: {
      competencyTitle: "Pursues continuous growth",
      leadershipArea: "Accountability",
      competencyDescription:
        "Pursues opportunities for growth through agile learning, and development of self-awareness",
      competencyItems: [
        "Building strong self-awareness of leadership strengths and derailers and critically reflecting on my own performance, practice and impact as a system leader",
        "Inspiring curiosity and passion for life-long learning by actively seeking feedback, setting development objectives and communicating openly about experiences",
        "Seizing opportunities to represent the sector and gain exposure to new environments, perspectives and ways of working"
      ]
    },
    demonstratesSoundGovernance: {
      competencyTitle: "Demonstrates sound governance",
      leadershipArea: "Accountability",
      competencyDescription:
        "Maintains a high standard of practice through governance and risk management",
      competencyItems: [
        "Role modelling impartiality and inspiring a system-wide culture that respects and appreciates policy frameworks and governance",
        "Operating as a trusted steward of public money and resources by setting organisational standards for governance",
        "Drawing on data forecasts and working across the system to identify and predict the global factors that may impact the realisation of government objectives",
        "Sponsoring system collaborations to assess risk and formulate mitigation strategies that protect sector-wide service delivery"
      ]
    }
  }
};