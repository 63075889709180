import Vue from "vue";
import App from "./App.vue";

// FontAwesome
import {
  library
} from "@fortawesome/fontawesome-svg-core";
import {
  fas
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// VueSlider
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
Vue.component("VueSlider", VueSlider);

// ApexCharts
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

// Router
import VueRouter from "vue-router";
import router from "./router";
Vue.use(VueRouter);

// Static data
import {
  competencyTable
} from "./staticData.js";

// Modal
import Modal from "./components/Modal.vue";
Vue.component("modal", Modal);

// Dialog
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

Vue.use(VuejsDialog);

// Vue Tippy
import VueTippy, {
  VueComponent
} from "vue-tippy";
Vue.use(VueTippy, {
  directive: "tippy", // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: true
      }
    }
  }
});
Vue.component("tippy", VueComponent);

// Axios

/* import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios); */

// TextArea
import VueTextareaAutosize from "vue-textarea-autosize";
Vue.use(VueTextareaAutosize);
Vue.config.productionTip = false;

// FireBase
import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var firebaseConfig = {
  apiKey: "AIzaSyDGALKt_AhmLkNYG84Gpkc6Mp-wte3XETA",
  authDomain: "competency-compass.firebaseapp.com",
  databaseURL: "https://competency-compass.firebaseio.com",
  projectId: "competency-compass",
  storageBucket: "competency-compass.appspot.com",
  messagingSenderId: "510848639947",
  appId: "1:510848639947:web:f0e39bda17fb6e1c"
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

window.addEventListener('beforeunload', (event) => {
  event.returnValue = "Are you sure you want to leave?";
});


new Vue({
  mounted: function () {
    this.shuffleArray(this.userCompetencies);
  },
  created: function () {
    this.staticData = competencyTable;
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        this.userLoggedIn = true;
      }
    });
    this.userCompetencies = this.competencyList;
  },
  methods: {
  
    // Randomise order of competencies
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    showModal() {
      this.modalActive = true;
    },
    hideModal() {
      this.modalActive = false;
    },
    checkProgression(screen) {
      if (!this.progression[screen]) {
        this.progression[screen] = true;
        this.modalActive = true;
      }
    },
    resetValues() {
      this.shuffleArray(this.userCompetencies);
      this.selectedLevel = null;
      this.averageRatings = [];
      this.averageOrder = [];
      this.userName = "";
      this.userAnswers = ["", "", ""];
      this.userEmphasis[0].ratings = [];
      this.userEmphasis[1].ratings = [];
      this.userEmphasis[2].ratings = [];
      this.ratingsSubmitted = false;
    }
  },
  components: {
    Modal
  },
  data: function () {
    return {
      development: false,
      progression: [false, false, false],
      selectedLevel: null,
      ratingsSubmitted: false,
      averageRatings: [],
      averageOrder: [],
      userName: "",
      userCompetencies: [],
      userLoggedIn: false,
      userAnswers: ["", "", ""],
      userEmphasis: [{
          leadershipLevel: "",
          competency: "",
          ratings: []
        },
        {
          leadershipLevel: "",
          competency: "",
          ratings: []
        },
        {
          leadershipLevel: "",
          competency: "",
          ratings: []
        }
      ],
      modalActive: false,
      staticData: competencyTable,
      competencyList: [{
          data: {
            competencySlug: "leadsStrategically",
            competencyTitle: "Leads strategically",
            competencyDescription: "Thinks critically and acts on the broader purpose of the system",
            competencyKeyword: "leading strategically",
            competencyArea: "vision"
          },
          order: 1
        },
        {
          data: {
            competencySlug: "stimulatesIdeasAndInnovation",
            competencyTitle: "Stimulates ideas and innovation",
            competencyDescription: "Gathers insights and embraces new ideas and innovation to inform future practice",
            competencyKeyword: "stimulating ideas and innovation",
            competencyArea: "vision"
          },
          order: 2
        },
        {
          data: {
            competencySlug: "leadsChangeInComplexEnvironments",
            competencyTitle: "Leads change in complex environments",
            competencyDescription: "Embraces change and leads with focus and optimism in an environment of complexity and ambiguity",
            competencyKeyword: "leading change in complex environments",
            competencyArea: "vision"
          },
          order: 3
        },
        {
          data: {
            competencySlug: "makesInsightfulDecisions",
            competencyTitle: "Makes insightful decisions",
            competencyDescription: "Makes considered, ethical and courageous decisions based on insight into the broader context",
            competencyKeyword: "making insightful decisions",
            competencyArea: "vision"
          },
          order: 4
        },
        {
          data: {
            competencySlug: "developsAndMobilisesTalent",
            competencyTitle: "Develops and mobilises talent",
            competencyDescription: "Strengthens and mobilises the unique talents and capabilities of the workforce",
            competencyKeyword: "developing and mobilising talent",
            competencyArea: "results"
          },
          order: 5
        },
        {
          data: {
            competencySlug: "buildsEnduringRelationships",
            competencyTitle: "Builds enduring relationships",
            competencyDescription: "Builds and sustains relationships to enable the collaborative delivery of customer-focused outcomes",
            competencyKeyword: "building enduring relationships",
            competencyArea: "results"
          },
          order: 6
        },
        {
          data: {
            competencySlug: "inspiresOthers",
            competencyTitle: "Inspires others",
            competencyDescription: "Inspires others by driving clarity, engagement and a sense of purpose",
            competencyKeyword: "inspiring others",
            competencyArea: "results"
          },
          order: 7
        },
        {
          data: {
            competencySlug: "drivesAccountabilityAndOutcomes",
            competencyTitle: "Drives accountability and outcomes",
            competencyDescription: "Demonstrates accountability for the execution and quality of results through professionalism, persistence and transparency",
            competencyKeyword: "driving accountability and outcomes",
            competencyArea: "results"
          },
          order: 8
        },
        {
          data: {
            competencySlug: "fostersHealthyAndInclusiveWorkplaces",
            competencyTitle: "Fosters healthy and inclusive workplaces",
            competencyDescription: "Fosters an inclusive workplace where health, safety and wellbeing is promoted and prioritised",
            competencyKeyword: "fostering healthy and inclusive workplaces",
            competencyArea: "accountability"
          },
          order: 9
        },
        {
          data: {
            competencySlug: "pursuesContinuousGrowth",
            competencyTitle: "Pursues continuous growth",
            competencyDescription: "Pursues opportunities for growth through agile learning, and development of self-awareness",
            competencyKeyword: "pursuing continuous growth",
            competencyArea: "accountability"
          },
          order: 10
        },
        {
          data: {
            competencySlug: "demonstratesSoundGovernance",
            competencyTitle: "Demonstrates sound governance",
            competencyDescription: "Maintains a high standard of practice through governance and risk management",
            competencyKeyword: "demonstrating sound governance",
            competencyArea: "accountability"
          },
          order: 11
        }
      ]
    };
  },
  router,
  render: h => h(App)
}).$mount("#app");