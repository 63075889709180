<template>
  <div class="app-wrap">
    <div class="content-wrap content-wrap--small">
      <h1 class="section-title">Part 1: Leadership stream</h1>
      <p class="text--center">Select your current leadership stream, and give your report a name.</p>
      <div class="form-wrap">
        <multiselect
          v-model="vueRoot.selectedLevel"
          placeholder="Select leadership level"
          deselectLabel
          :searchable="false"
          :options="options"
          label="name"
        ></multiselect>
        <input v-model="vueRoot.userName" class="form-control" type="text" placeholder="Name" />
        <p class="text--center">
          <em>
            Note, this name will only be used to personalise your report.
            <br />No personal data will be captured or stored in this app.
          </em>
        </p>
      </div>
    </div>

    <div class="cta-wrap">
      <div class="row">
        <div class="col-xs-12 text--center">
          <button
            class="btn btn--primary"
            :class="{ 'btn--disabled': !vueRoot.selectedLevel }"
            @click="anonymousAuthentication()"
          >Continue</button>
          <!--           <router-link
            to="/competencies"
            @click.native="anonymousAuthentication()"
            :class="{ 'btn--disabled': !vueRoot.selectedLevel }"
            class="btn btn--primary"
          >Continue</router-link>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import firebase from "firebase/app";

export default {
  name: "LevelSelection",
  components: { Multiselect },
  mounted: function() {},
  methods: {
    anonymousAuthentication() {
      var user = firebase.auth().currentUser;

      if (user) {
        this.$router.push({ path: "/competencies" });
      } else {
        firebase
          .auth()
          .signInAnonymously()
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    showErrorDialog() {
      let options = {
        okText: "Retry",
        cancelText: "Cancel"
      };

      this.$dialog
        .confirm(
          "Connectivity error. Please check your internet connection and retry.",
          options
        )
        .then(function(dialog) {
          this.$router.push({ path: "/selection" });
        })
        .catch(function() {});
    }
  },
  computed: {
    vueRoot() {
      return this.$root;
    }
  },
  data: function() {
    return {
      value: null,
      options: [
        { name: "Individual contributor", value: "individualContributor" },
        { name: "Team leader", value: "teamLeader" },
        { name: "Program leader", value: "programLeader" },
        { name: "Executive", value: "executive" },
        { name: "Chief executive", value: "chiefExecutive" }
      ]
    };
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>